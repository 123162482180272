import React from 'react';

import { Container, Typography } from "@mui/material";

const OplataDostvkaPage = () => {
    return (
        <Container sx={{ my: "30px" }}>
            <Typography variant="h5" className='title'>ОПЛАТА ТА ДОСТАВКА </Typography>
            <Typography variant="h5" className='subtitle'>
                СПОСОБИ ОПЛАТИ
            </Typography>
            <ul>
                <li>
                    <b>Онлайн оплата на сайті</b><br />
                    Зручно оплатити покупку на сайті можна за допомогою сервісу безпечних платежів Plata by Mono. Ви
                    можете
                    здійснити повну оплату ввівши всі необхідні дані вашої картки та підтвердивши платіж.
                </li>
                <li>
                    <b>Післяплата</b>, при отриманні у відділенні “Нової пошти” з завдатком 100 грн за доставку.<br />
                    <b>Зверніть увагу! </b>Нова пошта бере комісію за грошовий переказ:
                    20 грн + 2% накладеної суми. Комісію сплачує отримувач.
                </li>
            </ul>

            <Typography variant="h5" className='subtitle'>
                СПОСОБИ ДОСТАВКИ
            </Typography>
            <ul>
                <li>
                    <b>Транспортна компанія «Нова Пошта»</b><br />
                    Доставка здійснюється у відділення «Нова Пошта» та поштомати по всій Україні.<br />
                    Після того, як менеджер відправить ваше замовлення, ви отримаєте текстове повідомлення з номером
                    експрес накладної (ТТН).<br /> За цим номером ви зможете в будь-який момент відстежити ваше
                    замовлення на
                    офіційному сайті або у додатку компанії «Нова Пошта» і дізнатися, коли воно буде в відділенні.
                    <br />
                    Якщо Ви обрали сплатити замовлення за допомогою післяплати, з Вас буде взята комісія в розмірі 20
                    грн, яка стягується за грошовий переказ, а також 2% від загальної суми замовлення.<br />
                    Середній термін доставки посилки Україною — 1-3 дні.
                </li>
                <li>
                    <b>Транспортна компанія «УкрПошта»</b><br />
                    Отримати замовлення Укрпоштою Ви можете вказавши індекс відділення під час оформлення
                    замовлення.<br />
                    Замовлення УкрПоштою відправляються лише за умови <b>повної передоплати</b><br />
                    <b>Увага! </b>Огляд на пошті при замовленні Укрпоштою та накладений платіж доставкою Укрпошта –
                    відсутні.
                </li>
            </ul>
            <b>ВАЖЛИВО! </b><br />
            Послуги за доставку товарів сплачує клієнт.<br />
            Відправку замовлень здійснюємо кожного робочого дня (з Пн по Пт) .<br />
            Термін відправки 1-3 робочі дні .<br />
            Просимо своїх Клієнтів перевіряти товар на цілісність у відділенні Нової пошти. У разі пошкодження посилки
            при здійсненні доставки, кошти за пошкоджений товар відшкодовує компанія, яка надає послуги доставки,
            оскільки весь товар перед відправленням страхується на повну вартість.

        </Container>
    );
};

export { OplataDostvkaPage };